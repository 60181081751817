// Packages

// Components

// Logic

// Context

// Styles
import "./CharacterCard.css";

// Assets

export const CharacterCardLogic = () => {
	var entityTypes = ["Architect", "Enhanced", "Pseudo-Enhanced"];

	function getCardEntityTypeClassName(entityType) {
		var className = "characters-character-card-top-entity-type";
		switch (entityType) {
			case 0:
				className += " characters-character-card-top-entity-type-0";
				break;
			case 1:
				className += " characters-character-card-top-entity-type-1";
				break;
			case 2:
				className += " characters-character-card-top-entity-type-2";
				break;
			default:
				break;
		}
		return className;
	}

	return { entityTypes, getCardEntityTypeClassName };
};
