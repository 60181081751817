// Packages
import { useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Components
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { TopBar } from "./components/TopBar/TopBar";
import { Landing } from "./pages/Landing/Landing";
import { Characters } from "./pages/Characters/Characters";
import { Stories } from "./pages/Stories/Stories";
import { Events } from "./pages/Events/Events";
import { Locations } from "./pages/Locations/Locations";
import { Systems } from "./pages/Systems/Systems";
import { SystemsItem } from "./pages/SystemsItem/SystemsItem";
import { Lore } from "./pages/Lore/Lore";
import { Objects } from "./pages/Objects/Objects";

// Logic

// Context
import { PageContext } from "./context/PageContext";

// Styles
import "./App.css";

// Assets

function App() {
	const { pageRef } = useContext(PageContext);

	return (
		<BrowserRouter>
			<ScrollToTop>
				<div ref={pageRef} className='App'>
					<TopBar />
					<Switch>
						<Route exact path='/' component={Landing} />

						<Route exact path='/characters' component={Characters} />

						<Route exact path='/stories' component={Stories} />

						<Route exact path='/events' component={Events} />

						<Route exact path='/locations' component={Locations} />
						<Route exact path='/locations/systems' component={Systems} />
						<Route exact path='/locations/systems/:id' component={SystemsItem} />

						<Route exact path='/lore' component={Lore} />

						<Route exact path='/objects' component={Objects} />
					</Switch>
				</div>
			</ScrollToTop>
		</BrowserRouter>
	);
}

export default App;
