// Packages

// Components
import { GroupTabs } from "./GroupTabs";
import { CharacterCards } from "./CharacterCards";

// Logic
import { CharactersLogic } from "./CharactersLogic";

// Context

// Styles
import "../Page.css";
import "./Characters.css";

// Assets

export const Characters = () => {
	const { groups, openGroup, setOpenGroup } = CharactersLogic();

	return (
		<div className='characters'>
			<div className='page-top-bar-space'></div>
			<div className='page-title'>
				<h1>Characters</h1>
			</div>
			<GroupTabs groups={groups} openGroup={openGroup} setOpenGroup={setOpenGroup} />
			<CharacterCards groups={groups} openGroup={openGroup} />
		</div>
	);
};
