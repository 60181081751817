// Packages

// Components
import { CharacterCard } from "./CharacterCard";

// Logic

// Context

// Styles
import "./CharacterCards.css";

// Assets

export const CharacterCards = ({ groups, openGroup }) => {
	if (groups.length < openGroup + 1) return null;
	return (
		<div className='characters-character-cards-container'>
			<div className='characters-character-cards'>
				{groups[openGroup] === false
					? null
					: groups[openGroup].characters.map((character, index) => <CharacterCard key={index} character={character} />)}
			</div>
		</div>
	);
};
