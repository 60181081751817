// Packages
import { useHistory } from "react-router-dom";
import { FaUser, FaBookOpen, FaClock, FaLocationArrow, FaFileAlt, FaSpaceShuttle } from "react-icons/fa";

// Components

// Logic

// Context

// Styles
import "./TopBar.css";

// Assets
import logo from "../../assets/logo.svg";

export const TopBar = () => {
	var history = useHistory();

	function goToPage(page) {
		history.push("/" + page);
	}

	return (
		<div className='top-bar'>
			<div className='top-bar-buttons'>
				<div className='top-bar-btn' onClick={() => goToPage("characters")}>
					<FaUser />
				</div>
				<div className='top-bar-btn' onClick={() => goToPage("stories")}>
					<FaBookOpen />
				</div>
				<div className='top-bar-btn' onClick={() => goToPage("events")}>
					<FaClock />
				</div>
				<div className='top-bar-btn top-bar-btn-home' onClick={() => goToPage("")}>
					<img src={logo} alt='home' />
				</div>
				<div className='top-bar-btn' onClick={() => goToPage("locations")}>
					<FaLocationArrow />
				</div>
				<div className='top-bar-btn' onClick={() => goToPage("lore")}>
					<FaFileAlt />
				</div>
				<div className='top-bar-btn' onClick={() => goToPage("objects")}>
					<FaSpaceShuttle />
				</div>
			</div>
		</div>
	);
};
