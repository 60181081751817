// Packages
import { FaHardHat } from "react-icons/fa";

// Components

// Logic

// Context

// Styles
import "./PageNotComplete.css";

// Assets

export const PageNotComplete = () => {
	return (
		<div className='page-not-complete-container'>
			<FaHardHat />
			<p>This page is still being worked on.</p>
		</div>
	);
};
