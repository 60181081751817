// Packages
import { useEffect, useState } from "react";

// Components

// Logic

// Context

// Styles

// Assets

export const CharactersLogic = () => {
	const [groups, setGroups] = useState([]);
	const [openGroup, setOpenGroup] = useState(0);

	useEffect(() => {
		getCharacters();
	}, []);

	function getCharacters() {
		var groupIDs = require("../../data/groups/groups.json").groups;

		var newGroups = groupIDs.map((groupID) => {
			try {
				var group = JSON.parse(JSON.stringify(require("../../data/groups/" + groupID + "/" + groupID + ".json")));
				group.characters = group.characters.map((characterID) => {
					try {
						var character = require("../../data/characters/" + characterID + "/" + characterID + ".json");
						character.cardImage = false;
						try {
							var cardImage = require("../../data/characters/" + characterID + "/" + characterID + "-card-image.json");
							if (cardImage.cardImage !== undefined && cardImage.cardImage !== false) character.cardImage = cardImage.cardImage;
						} catch (e) {}
					} catch (e) {}
					if (character) return character;
					return false;
				});
			} catch (e) {}
			if (group) return group;
			return false;
		});

		setGroups(newGroups);
	}

	return { groups, openGroup, setOpenGroup };
};
