// Packages

// Components

// Logic

// Context

// Styles
import "./CountryCard.css";

// Assets

export const CountryCard = ({ country }) => {
	return (
		<div className='locations-country-card' style={country.colour ? { borderColor: country.colour } : {}}>
			{country.flagImage ? (
				<img
					className='locations-country-card-background-image'
					src={country.flagImage}
					alt=''
					style={country.colour ? { borderColor: country.colour } : {}}
				/>
			) : (
				<div className='locations-country-card-background-colour' style={country.colour ? { borderColor: country.colour } : {}} />
			)}

			<div className='locations-country-card-content'>
				<div className='locations-country-card-top'>
					<div className='locations-country-card-top-name' style={country.colour ? { color: country.colour } : {}}>
						{country.name}
					</div>
				</div>
				<div className='locations-country-card-info-container'>
					{!country.officialName ? null : (
						<div className='locations-country-card-info' style={country.colour ? { background: country.colour } : {}}>
							<p className='locations-country-card-info-label'>Official Name</p>
							<p className='locations-country-card-info-value'>{country.officialName}</p>
						</div>
					)}
					{!country.powerSource ? null : (
						<div className='locations-country-card-info' style={country.colour ? { background: country.colour } : {}}>
							<p className='locations-country-card-info-label'>Power Source</p>
							<p className='locations-country-card-info-value'>{country.powerSource}</p>
						</div>
					)}
					{!country.powerStructure ? null : (
						<div className='locations-country-card-info' style={country.colour ? { background: country.colour } : {}}>
							<p className='locations-country-card-info-label'>Power Structure</p>
							<p className='locations-country-card-info-value'>{country.powerStructure}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
