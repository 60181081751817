// Packages
import { useRef, useState, useEffect, useContext } from "react";

// Components

// Logic

// Context
import { PageContext } from "../../context/PageContext";

// Styles

// Assets

export const LandingLogic = () => {
	const { pageRef } = useContext(PageContext);
	const landingFrontRef = useRef();
	const landingInfoRef = useRef();
	const [isScrolling, setIsScrolling] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			if (pageRef && pageRef.current && landingFrontRef && landingFrontRef.current)
				landingFrontRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}, 200);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (pageRef && pageRef.current && landingFrontRef && landingFrontRef.current && landingInfoRef && landingInfoRef.current) {
		}
		pageRef.current.addEventListener("wheel", landingPageScroll);
		return () => {
			// eslint-disable-next-line
			pageRef.current.removeEventListener("wheel", landingPageScroll);
		};
	});

	function landingPageScroll(e) {
		e.preventDefault();
		if (isScrolling) return;

		// Scroll to Front
		if (Math.sign(e.deltaY) === -1 && pageRef.current.parentNode.parentNode.parentNode.scrollTop <= landingFrontRef.current.clientHeight + 50) {
			setIsScrolling(true);

			landingFrontRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

			setTimeout(() => {
				setIsScrolling(false);
			}, 500);

			return;
		}

		// Scroll to Info
		if (Math.sign(e.deltaY) === 1 && pageRef.current.parentNode.parentNode.parentNode.scrollTop <= landingFrontRef.current.clientHeight - 10) {
			setIsScrolling(true);

			landingInfoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

			setTimeout(() => {
				setIsScrolling(false);
			}, 500);
			return;
		}

		customScroll(40, 4, 2, Math.sign(e.deltaY));
	}

	function customScroll(iterations, interval, modifier, direction) {
		for (var i = 0; i < iterations - 1; i++) {
			setTimeout(() => {
				if (pageRef.current.parentNode.parentNode.parentNode.scrollTop + modifier * direction > landingFrontRef.current.clientHeight)
					pageRef.current.parentNode.parentNode.parentNode.scrollTop += modifier * direction;
			}, i * interval);
		}

		// pageRef.current.parentNode.parentNode.parentNode.scroll({
		// 	top: pageRef.current.parentNode.parentNode.parentNode.scrollTop + 100 * Math.sign(e.deltaY),
		// 	left: 0,
		// 	behavior: "smooth",
		// });
	}

	return { landingFrontRef, landingInfoRef };
};
