// Packages

// Components
import { PageNotComplete } from "../../components/PageNotComplete/PageNotComplete";

// Logic

// Context

// Styles
import "../Page.css";
import "./Objects.css";

// Assets

export const Objects = () => {
	return (
		<div className='objects'>
			<div className='page-top-bar-space'></div>
			<div className='objects-front'></div>
			<PageNotComplete />
		</div>
	);
};
