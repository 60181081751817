// Packages

// Components
import { PageNotComplete } from "../../components/PageNotComplete/PageNotComplete";

// Logic

// Context

// Styles
import "./SystemsItem.css";

// Assets

export const SystemsItem = () => {
	return (
		<div className='systems-item'>
			<div className='page-top-bar-space'></div>
			<PageNotComplete />
		</div>
	);
};
