// Packages

// Components
import { CharacterCard } from "./CharacterCard";

// Logic
import { CharacterCardsLogic } from "./CharacterCardsLogic";

// Context

// Styles
import "./CharacterCards.css";

// Assets

export const CharacterCards = () => {
	const { characters } = CharacterCardsLogic();

	return (
		<div className='landing-info-character-cards-container'>
			<div className='landing-info-character-cards-title'>Featured Characters</div>
			<div className='landing-info-character-cards'>
				{characters.map((character, index) => (
					<CharacterCard key={index} character={character} />
				))}
			</div>
		</div>
	);
};
