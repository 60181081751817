import React, { createContext, useRef } from "react";

export const PageContext = createContext();

// eslint-disable-next-line
export default ({ children }) => {
	const pageRef = useRef();

	return <PageContext.Provider value={{ pageRef }}>{children}</PageContext.Provider>;
};
