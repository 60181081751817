// Packages

// Components
import { PageNotComplete } from "../../components/PageNotComplete/PageNotComplete";

// Logic

// Context

// Styles
import "../Page.css";
import "./Lore.css";

// Assets

export const Lore = () => {
	return (
		<div className='lore'>
			<div className='page-top-bar-space'></div>
			<div className='lore-front'></div>
			<PageNotComplete />
		</div>
	);
};
