// Packages

// Components
import { CharacterCards } from "./CharacterCards";

// Logic
import { LandingLogic } from "./LandingLogic";

// Context

// Styles
import "./Landing.css";
// import "./LandingFrontTitleFonts.css";

// Assets
import logo from "../../assets/logo.svg";

export const Landing = () => {
	const { landingFrontRef, landingInfoRef } = LandingLogic();

	return (
		<div className='landing'>
			<div ref={landingFrontRef} className='landing-front'>
				<div className='landing-front-title'>Nova Cosmos</div>
				<img className='landing-front-logo' src={logo} alt='' />
			</div>
			<div ref={landingInfoRef} className='landing-info'>
				<div className='landing-info-title'>The Nova Cosmos</div>
				<div className='landing-info-description'>
					A group of intelligent teenagers endeavour to birth a new universe with the goal of restarting civilisation, absent of its
					current totalitarian world order.
				</div>
				<CharacterCards />
			</div>
		</div>
	);
};
