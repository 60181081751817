// Packages

// Components

// Logic
import { CharacterCardLogic } from "./CharacterCardLogic";

// Context

// Styles
import "./CharacterCard.css";

// Assets

export const CharacterCard = ({ character }) => {
	const { entityTypes, getCardEntityTypeClassName } = CharacterCardLogic();

	if (character === undefined || character === false) return null;
	return (
		<div className='characters-character-card' style={character.colour ? { borderColor: character.colour } : {}}>
			{character.cardImage ? (
				<img
					className='characters-character-card-background-image'
					src={character.cardImage}
					alt=''
					style={character.colour ? { borderColor: character.colour } : {}}
				/>
			) : (
				<div className='characters-character-card-background-colour' style={character.colour ? { borderColor: character.colour } : {}} />
			)}

			<div className='characters-character-card-content'>
				<div className='characters-character-card-top'>
					<div className='characters-character-card-top-name' style={character.colour ? { color: character.colour } : {}}>
						{character.name}
					</div>
					{character.entityType === undefined ? null : (
						<div className={getCardEntityTypeClassName(character.entityType)}>
							<p>{entityTypes[character.entityType]}</p>
						</div>
					)}
				</div>
				<div className='characters-character-card-info-container'>
					{!character.fullName ? null : (
						<div
							className='characters-character-card-info characters-character-card-info-full-name'
							style={character.colour ? { background: character.colour } : {}}
						>
							<p className='characters-character-card-info-label'>Full Name</p>
							<p className='characters-character-card-info-value'>{character.fullName}</p>
						</div>
					)}
					{!character.primaryAbility ? null : (
						<div className='characters-character-card-info' style={character.colour ? { background: character.colour } : {}}>
							<p className='characters-character-card-info-label'>Primary Ability</p>
							<p className='characters-character-card-info-value'>{character.primaryAbility}</p>
						</div>
					)}
					{!character.descriptives ? null : (
						<div className='characters-character-card-info' style={character.colour ? { background: character.colour } : {}}>
							<p className='characters-character-card-info-label'>Descriptives</p>
							<p className='characters-character-card-info-value'>{character.descriptives}</p>
						</div>
					)}
					{!character.represents ? null : (
						<div className='characters-character-card-info' style={character.colour ? { background: character.colour } : {}}>
							<p className='characters-character-card-info-label'>Represents</p>
							<p className='characters-character-card-info-value'>{character.represents}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
