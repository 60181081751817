// Packages
import { useEffect, useState } from "react";

// Components

// Logic

// Context

// Styles

// Assets

export const CharacterCardsLogic = () => {
	const [characters, setCharacters] = useState([]);

	useEffect(() => {
		getCharacters();
	}, []);

	function getCharacters() {
		var characterIDs = ["canos", "xenos", "amio", "hyren"];

		var newCharacters = characterIDs.map((characterID) => {
			var character = require("../../data/characters/" + characterID + "/" + characterID + ".json");
			character.cardImage = false;
			try {
				var cardImage = require("../../data/characters/" + characterID + "/" + characterID + "-card-image.json");
				if (cardImage.cardImage !== undefined && cardImage.cardImage !== false) character.cardImage = cardImage.cardImage;
			} catch (e) {}
			return character;
		});

		setCharacters(newCharacters);
	}

	return { characters };
};
