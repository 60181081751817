// Packages

// Components
import { PageNotComplete } from "../../components/PageNotComplete/PageNotComplete";

// Logic

// Context

// Styles
import "../Page.css";
import "./Stories.css";

// Assets

export const Stories = () => {
	return (
		<div className='stories'>
			<div className='page-top-bar-space'></div>
			<div className='stories-front'></div>
			<PageNotComplete />
		</div>
	);
};
