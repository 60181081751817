// Packages

// Components
import { CountryCard } from "./CountryCard";

// Logic
import { CountriesLogic } from "./CountriesLogic";

// Context

// Styles
import "../Page.css";
import "./Countries.css";

// Assets

export const Countries = () => {
	const { countries } = CountriesLogic();

	return (
		<div className='locations-countries-container'>
			<div className='page-subtitle'>
				<h2>Countries</h2>
			</div>
			<div className='locations-country-cards-container'>
				{countries.map((country, index) => (
					<CountryCard key={index} country={country} />
				))}
			</div>
		</div>
	);
};
