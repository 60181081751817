// Packages
import { useHistory } from "react-router-dom";
import { FaArrowRight, FaMap } from "react-icons/fa";

// Components
import { Countries } from "./Countries";

// Logic

// Context

// Styles
import "../Page.css";
import "./Locations.css";

// Assets

export const Locations = () => {
	var history = useHistory();

	return (
		<div className='locations'>
			<div className='page-top-bar-space'></div>
			<div className='page-title'>
				<h1>Locations</h1>
			</div>
			<div className='locations-systems-btn' onClick={() => history.push("/locations/systems")}>
				<div className='locations-systems-btn-hover'></div>
				<div className='locations-systems-btn-map-icon'>
					<FaMap />
				</div>
				<div className='locations-systems-btn-name'>Go to Systems Map</div>
				<div className='locations-systems-btn-arrow-icon'>
					<FaArrowRight />
				</div>
			</div>
			<Countries />
		</div>
	);
};
