// Packages

// Components
import { SystemsMap } from "./SystemsMap";

// Logic

// Context

// Styles
import "../Page.css";
import "./Systems.css";

// Assets

export const Systems = () => {
	return (
		<div className='systems'>
			<div className='page-top-bar-space'></div>
			<SystemsMap />
		</div>
	);
};
