// Packages
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Sketch from "react-p5";

// Components

// Logic

// Context

// Styles

// Assets
import systemsStars from "../../assets/systemsStars.png";
import systems from "../../data/locations/systems/systems.json";

export const SystemsMap = () => {
	var history = useHistory();
	let bg;
	var zoom = 1;
	var zoomOffsetX = 0;
	var zoomOffsetY = 40;
	var zoomSensitivity = 0.002;
	var zoomMax = 2000;

	var touches = [];
	var touches0 = [];
	var touches1 = [];

	useEffect(() => {
		document.addEventListener("touchmove", touchMoveHandler, { passive: false });
		return () => {
			document.removeEventListener("touchmove", touchMoveHandler);
		};
	});

	function touchMoveHandler(e) {
		e.preventDefault();
	}

	function preload(p5) {
		bg = p5.loadImage(systemsStars);
	}

	function setup(p5) {
		p5.createCanvas(window.innerWidth, window.innerHeight - 80);
	}

	function windowResized(p5) {
		p5.resizeCanvas(window.innerWidth, window.innerHeight - 80);
	}

	function draw(p5) {
		p5.background(0);
		p5.background(bg);
		displaySystems(p5);
		mobileGestures(p5);
	}

	// Controls

	function mouseWheel(p5) {
		var maxRadius = 400 * zoom;
		if ((zoom > 0.8 || p5._mouseWheelDeltaY < 0) && (zoom < zoomMax || p5._mouseWheelDeltaY > 0)) {
			zoom = zoom + p5._mouseWheelDeltaY * (zoomSensitivity * -1) * zoom;
			if (p5._mouseWheelDeltaY > 0) {
				// Zoom out
				zoomOffsetX += ((p5.mouseX - window.innerWidth / 2) * (zoomSensitivity * 200)) / zoom;
				zoomOffsetY += ((p5.mouseY - window.innerHeight / 2) * (zoomSensitivity * 200)) / zoom;
			} else {
				// Zoom in
				zoomOffsetX += ((p5.mouseX - window.innerWidth / 2) * (zoomSensitivity * -200)) / zoom;
				zoomOffsetY += ((p5.mouseY - window.innerHeight / 2) * (zoomSensitivity * -200)) / zoom;
			}
		}
		if (zoom < 0.5) zoom = 0.8;
		if (zoom > 2500) zoom = 2000;
		if (zoomOffsetX > maxRadius) zoomOffsetX = maxRadius;
		if (zoomOffsetX < maxRadius * -1) zoomOffsetX = maxRadius * -1;
		if (zoomOffsetY > maxRadius) zoomOffsetY = maxRadius;
		if (zoomOffsetY < maxRadius * -1) zoomOffsetY = maxRadius * -1;
	}

	function mobileGestures(p5) {
		if (p5.touches.length === 0) {
			touches = [];
			touches0 = [];
			touches1 = [];
		}
		if (p5.touches.length === 1) {
			touches.push(p5.touches[0]);
			if (touches.length > 1) {
				zoomOffsetX += (touches[touches.length - 1].x - touches[touches.length - 2].x) / zoom;
				zoomOffsetY += (touches[touches.length - 1].y - touches[touches.length - 2].y) / zoom;
			}
		}
		if (p5.touches.length === 2) {
			touches0.push(p5.touches[0]);
			touches1.push(p5.touches[1]);
			if (touches0.length > 1) {
				var zoomChange =
					((Math.abs(touches1[touches1.length - 1].x) > Math.abs(touches0[touches0.length - 1].x)
						? Math.abs(touches1[touches1.length - 1].x) - Math.abs(touches0[touches0.length - 1].x)
						: Math.abs(touches0[touches0.length - 1].x) - Math.abs(touches1[touches1.length - 1].x)) -
						(Math.abs(touches1[touches1.length - 2].x) > Math.abs(touches0[touches0.length - 2].x)
							? Math.abs(touches1[touches1.length - 2].x) - Math.abs(touches0[touches0.length - 2].x)
							: Math.abs(touches0[touches0.length - 2].x) - Math.abs(touches1[touches1.length - 2].x)) +
						((Math.abs(touches1[touches1.length - 1].y) > Math.abs(touches0[touches0.length - 1].y)
							? Math.abs(touches1[touches1.length - 1].y) - Math.abs(touches0[touches0.length - 1].y)
							: Math.abs(touches0[touches0.length - 1].y) - Math.abs(touches1[touches1.length - 1].y)) -
							(Math.abs(touches1[touches1.length - 2].y) > Math.abs(touches0[touches0.length - 2].y)
								? Math.abs(touches1[touches1.length - 2].y) - Math.abs(touches0[touches0.length - 2].y)
								: Math.abs(touches0[touches0.length - 2].y) - Math.abs(touches1[touches1.length - 2].y)))) *
					(zoom * 0.004);
				if (zoomChange < 0 && zoom < 0.4) zoomChange = 0;
				if (zoom < 0.4) zoom = 0.4;
				zoom = zoom + zoomChange;
			}
		}
	}

	// Systems

	function displaySystems(p5) {
		// Routes
		if (zoom < 10) {
			systemRoute(p5, 0, 1, systems[0].x, systems[0].y, systems[1].x, systems[1].y);
			systemRoute(p5, 0, 2, systems[0].x, systems[0].y, systems[2].x, systems[2].y);
			systemRoute(p5, 0, 3, systems[0].x, systems[0].y, systems[3].x, systems[3].y);
			systemRoute(p5, 0, 4, systems[0].x, systems[0].y, systems[4].x, systems[4].y);
			systemRoute(p5, 0, 8, systems[0].x, systems[0].y, systems[8].x, systems[8].y);
			systemRoute(p5, 0, 11, systems[0].x, systems[0].y, systems[11].x, systems[11].y);
			systemRoute(p5, 1, 10, systems[1].x, systems[1].y, systems[10].x, systems[10].y);
			systemRoute(p5, 1, 12, systems[1].x, systems[1].y, systems[12].x, systems[12].y);
			systemRoute(p5, 2, 5, systems[2].x, systems[2].y, systems[5].x, systems[5].y);
			systemRoute(p5, 3, 6, systems[3].x, systems[3].y, systems[6].x, systems[6].y);
			systemRoute(p5, 3, 18, systems[3].x, systems[3].y, systems[18].x, systems[18].y);
			systemRoute(p5, 4, 7, systems[4].x, systems[4].y, systems[7].x, systems[7].y);
			systemRoute(p5, 4, 14, systems[4].x, systems[4].y, systems[14].x, systems[14].y);
			systemRoute(p5, 5, 13, systems[5].x, systems[5].y, systems[13].x, systems[13].y);
			systemRoute(p5, 5, 19, systems[5].x, systems[5].y, systems[19].x, systems[19].y);
			systemRoute(p5, 6, 9, systems[6].x, systems[6].y, systems[9].x, systems[9].y);
			systemRoute(p5, 6, 16, systems[6].x, systems[6].y, systems[16].x, systems[16].y);
			systemRoute(p5, 7, 15, systems[7].x, systems[7].y, systems[15].x, systems[15].y);
			systemRoute(p5, 7, 17, systems[7].x, systems[7].y, systems[17].x, systems[17].y);
			systemRoute(p5, 10, 20, systems[10].x, systems[10].y, systems[20].x, systems[20].y);
		}

		// Systems
		for (var systemIndex = 0; systemIndex < systems.length; systemIndex++) {
			var system = systems[systemIndex];
			for (var starIndex = 0; starIndex < system.stars.length; starIndex++) {
				var s = system.stars[starIndex];
				star(p5, starSize(s.mass), s.mass, offsetCenterVector(p5, system.x + s.x, system.y + s.y), s.colour, s.name, s.link);
			}
			for (var planetIndex = 0; planetIndex < system.planets.length; planetIndex++) {
				var p = system.planets[planetIndex];
				planet(p5, planetSize(p.mass), p.mass, planetOrbit(p5, p.semiMajorAxis, system.x, system.y), p.colour, p.name, p.link);
			}
		}
	}

	function systemRoute(p5, i1, i2, x1, y1, x2, y2) {
		p5.stroke(50);
		p5.strokeWeight(0.8);
		var mainRoute =
			(i1 === 0 && i2 === 1) ||
			(i1 === 0 && i2 === 3) ||
			(i1 === 0 && i2 === 4) ||
			(i1 === 3 && i2 === 6) ||
			(i1 === 4 && i2 === 7) ||
			(i1 === 1 && i2 === 10) ||
			(i1 === 10 && i2 === 20) ||
			(i1 === 6 && i2 === 16);
		if (mainRoute) p5.stroke(120);
		p5.strokeWeight(2.5);
		if (i2 === 17) p5.stroke("#0022aa");
		p5.strokeWeight(2.5);
		p5.line(offsetCenterX(x1), offsetCenterY(y1), offsetCenterX(x2), offsetCenterY(y2));
	}

	function offsetCenterX(offsetX) {
		return window.innerWidth / 2 + (offsetX + zoomOffsetX) * zoom;
	}

	function offsetCenterY(offsetY) {
		return window.innerHeight / 2 - 60 + (offsetY + zoomOffsetY) * zoom;
	}

	// Stars

	function star(p5, mass, initialMass, pos, colour, name, link) {
		p5.noStroke();
		if (zoom < 10) {
			p5.fill("#ffffff");
		} else {
			switch (colour) {
				case "red":
					p5.fill("#ff8800");
					break;
				default:
					p5.fill("#ff8800");
					break;
			}
		}
		p5.ellipse(pos.x, pos.y, mass, mass);

		// Label
		var hoverBox = mass * 1.5;
		if (zoom >= 10) hoverBox = mass * 0.6;
		if (p5.mouseX < pos.x + hoverBox && p5.mouseX > pos.x - hoverBox && p5.mouseY < pos.y + hoverBox && p5.mouseY > pos.y - hoverBox) {
			bodyLabel(p5, "star", mass, initialMass, name, pos);
			if (((p5.mouseIsPressed && p5.mouseButton === "left") || p5.touches.length === 1) && link !== undefined)
				history.push("/locations/systems/" + link);
		}
	}

	function starSize(mass) {
		if (zoom < 10) {
			return mass * 0.8;
		} else {
			return mass * (zoom * 0.05);
		}
	}

	// Planets

	function planet(p5, mass, initialMass, pos, colour, name, link) {
		p5.noStroke();
		p5.fill(colour);
		p5.ellipse(pos.x, pos.y, mass, mass);

		// Label
		var hoverBox = mass * 0.6;
		if (
			zoom > 10 &&
			p5.mouseX < pos.x + hoverBox &&
			p5.mouseX > pos.x - hoverBox &&
			p5.mouseY < pos.y + hoverBox &&
			p5.mouseY > pos.y - hoverBox
		) {
			bodyLabel(p5, "planet", mass, initialMass, name, pos);
			if (((p5.mouseIsPressed && p5.mouseButton === "left") || p5.touches.length === 1) && link !== undefined)
				history.push("/archives/locations/" + link);
		}
	}

	function planetSize(mass) {
		if (zoom < 10) {
			return 0;
		} else if (mass < 2) {
			return mass * (zoom / 15);
		} else {
			return mass * (zoom / 50);
		}
	}

	function planetOrbit(p5, semiMajorAxis, systemX, systemY) {
		return offsetCenterVector(p5, systemX + semiMajorAxis, systemY);
	}

	// Position
	function offsetCenterVector(p5, offsetX, offsetY) {
		return p5.createVector(
			window.innerWidth / 2 + (offsetX + zoomOffsetX) * zoom,
			window.innerHeight / 2 - 60 + (offsetY + zoomOffsetY) * zoom
		);
	}

	// Labels
	function bodyLabel(p5, bodyType, mass, initialMass, bodyName, pos) {
		let text;
		switch (bodyType) {
			case "star":
				if (zoom < 60) {
					text = p5.text(bodyName, pos.x + 20 + zoom * 0.4, pos.y - 20 - zoom * 0.04);
					p5.stroke(255);
					p5.line(pos.x + 15 + zoom * 0.4, pos.y - 15 - zoom * 0.04, pos.x + mass / 2, pos.y - mass / 2);
					p5.line(
						pos.x + 15 + zoom * 0.4,
						pos.y - 15 - zoom * 0.04,
						pos.x + 15 + p5.textWidth(bodyName) + 5 + zoom * 0.4,
						pos.y - 15 - zoom * 0.04
					);
				} else {
					text = p5.text(bodyName, pos.x + 40 + zoom * 0.2, pos.y - 40 - zoom * 0.2);
					p5.stroke(255);
					p5.line(pos.x + 35 + zoom * 0.2, pos.y - 35 - zoom * 0.2, pos.x + mass / 3 + 10, pos.y - mass / 3 - 10);
					p5.line(
						pos.x + 35 + zoom * 0.2,
						pos.y - 35 - zoom * 0.2,
						pos.x + 35 + p5.textWidth(bodyName) + 5 + zoom * 0.2,
						pos.y - 35 - zoom * 0.2
					);
				}
				break;
			case "planet":
				if (zoom < 60) {
					text = p5.text("", pos.x + zoom * (mass * 0.01), pos.y - zoom * (mass * 0.01));
				} else if (zoom > 60 && zoom < 200) {
					text = p5.text(bodyName, pos.x + 20 + zoom * 0.2, pos.y - 20 - zoom * 0.2);
					p5.stroke(255);
					p5.line(pos.x + 15 + zoom * 0.2, pos.y - 15 - zoom * 0.2, pos.x + mass / 3 + 10, pos.y - mass / 3 - 10);
					p5.line(
						pos.x + 15 + zoom * 0.2,
						pos.y - 15 - zoom * 0.2,
						pos.x + 15 + p5.textWidth(bodyName) + 5 + zoom * 0.2,
						pos.y - 15 - zoom * 0.2
					);
				} else if (zoom < 600 && initialMass < 2) {
					text = p5.text(bodyName, pos.x + 10 + zoom * 0.1, pos.y - 10 - zoom * 0.1);
					p5.stroke(255);
					p5.line(pos.x + 5 + zoom * 0.1, pos.y - 5 - zoom * 0.1, pos.x + mass / 3 + 10, pos.y - mass / 3 - 10);
					p5.line(
						pos.x + 5 + zoom * 0.1,
						pos.y - 5 - zoom * 0.1,
						pos.x + 10 + p5.textWidth(bodyName) + zoom * 0.1,
						pos.y - 5 - zoom * 0.1
					);
				} else if (zoom < 600 && initialMass > 2) {
					text = p5.text(bodyName, pos.x + 20 + zoom * 0.2, pos.y - 20 - zoom * 0.2);
					p5.stroke(255);
					p5.line(pos.x + 15 + zoom * 0.2, pos.y - 15 - zoom * 0.2, pos.x + mass / 3 + 10, pos.y - mass / 3 - 10);
					p5.line(
						pos.x + 15 + zoom * 0.2,
						pos.y - 15 - zoom * 0.2,
						pos.x + 15 + p5.textWidth(bodyName) + zoom * 0.2,
						pos.y - 15 - zoom * 0.2
					);
				} else if (zoom > 600 && initialMass > 2) {
					text = p5.text(bodyName, pos.x + 10 + zoom * 0.1, pos.y - 10 - zoom * 0.1);
					p5.stroke(255);
					p5.line(pos.x + 5 + zoom * 0.1, pos.y - 5 - zoom * 0.1, pos.x + mass / 3 + 10, pos.y - mass / 3 - 10);
					p5.line(
						pos.x + 5 + zoom * 0.1,
						pos.y - 5 - zoom * 0.1,
						pos.x + 5 + p5.textWidth(bodyName) + zoom * 0.1,
						pos.y - 5 - zoom * 0.1
					);
				} else {
					text = p5.text(bodyName, pos.x + 10 + zoom * 0.05, pos.y - 10 - zoom * 0.05);
					p5.stroke(255);
					p5.line(pos.x + 5 + zoom * 0.05, pos.y - 5 - zoom * 0.05, pos.x + mass / 3 + 10, pos.y - mass / 3 - 10);
					p5.line(
						pos.x + 5 + zoom * 0.05,
						pos.y - 5 - zoom * 0.05,
						pos.x + 10 + p5.textWidth(bodyName) + zoom * 0.05,
						pos.y - 5 - zoom * 0.05
					);
				}
				break;
			default:
				text = p5.text(bodyName, pos.x + mass + zoom * 0.9, pos.y - mass - zoom * 0.9);
				break;
		}

		text.textSize(18);
		text.fill(255);
	}

	return <Sketch preload={preload} setup={setup} windowResized={windowResized} mouseWheel={mouseWheel} draw={draw} />;
};
