// Packages
import { useState, useEffect } from "react";

// Components

// Logic

// Context

// Styles

// Assets

export const CountriesLogic = () => {
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		getCountries();
	}, []);

	function getCountries() {
		var countryIDs = require("../../data/locations/countries/countries.json").countries;

		var newCountries = countryIDs.map((countryID) => {
			try {
				var country = JSON.parse(JSON.stringify(require("../../data/locations/countries/" + countryID + "/" + countryID + ".json")));
			} catch (e) {}
			if (country) return country;
			return false;
		});

		setCountries(newCountries);
	}

	return { countries };
};
