// Packages

// Components

// Logic

// Context

// Styles
import "./GroupTabs.css";

// Assets

export const GroupTabs = ({ groups, openGroup, setOpenGroup }) => {
	return (
		<div className='characters-groups-container'>
			{groups.map((group, index) => (
				<button
					key={index}
					className={openGroup === index ? "characters-group-btn characters-group-btn-active" : "characters-group-btn"}
					onClick={openGroup === index ? () => {} : () => setOpenGroup(index)}
				>
					{group.name}
				</button>
			))}
		</div>
	);
};
